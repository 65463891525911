/**
 * using ../public/fonts instead of /fonts lets webpack do its thing and
 * load with hashed urls
 */
@font-face {
  font-family: 'Sport1-Bold';
  src: url('../public/fonts/Sport1-Bold.woff2') format('woff2'),
       url('../public/fonts/Sport1-Bold.woff') format('woff'),
       url('../public/fonts/Sport1-Bold.ttf') format('ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Sport1-Regular';
  src: url('../public/fonts/Sport1-Regular.woff2') format('woff2'),
       url('../public/fonts/Sport1-Regular.woff') format('woff'),
       url('../public/fonts/Sport1-Regular.ttf') format('ttf');
  font-display: swap;
}
@font-face {
    font-family: 'Sport1-CondensedBlackItalic';
    src: url('../public/fonts/Sport1-CondensedBlackItalic.woff2') format('woff2'),
         url('../public/fonts/Sport1-CondensedBlackItalic.otf') format('otf');
}
@font-face {
  font-family: 'Sport1-CondensedRegularItalic';
  src: url('../public/fonts/Sport1-CondensedRegularItalic.woff2') format('woff2'),
}
@font-face {
  font-family: 'Sport1-RegularItalic';
  src: url('../public/fonts/Sport1-RegularItalic.woff2') format('woff2'),
}
@font-face {
  font-family: 'sport1-styleguide';
  src: url('../public/fonts/fontello/sport1-styleguide.woff2');
}
@font-face {
  font-family: 'sport1-universal-components';
  src: url('../public/fonts/fontello/sport1-universal-components.ttf');
}
@font-face {
  font-family: 'Sport1-Condensed';
  src: url('../public/fonts/Sport1-CondensedBlackItalic.otf');
}

/* Fallbacks */
@font-face {
  font-family: "Sport1-CondensedBlackItalic-Fallback";
  src: local(Arial);
  size-adjust: 78%;
  descent-override: 15%;
}

@font-face {
  font-family: "Sport1-CondensedRegularItalic-Fallback";
  src: local(Arial);
  size-adjust: 78%;
  descent-override: 15%;
}

@font-face {
  font-family: "Sport1-RegularItalic-Fallback";
  src: local(Arial);
  size-adjust: 78%;
  descent-override: 15%;
}

@font-face {
  font-family: "Sport1-Regular-Fallback";
  src: local(Arial);
  size-adjust: 93.6%;
  descent-override: 15%;
}

@font-face {
  font-family: "Sport1-Bold-Fallback";
  src: local(Arial);
  size-adjust: 98.4%;
  descent-override: 15%;
}