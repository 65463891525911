[class*=' Account__SublinksContainer'] {
  justify-content: space-evenly !important;
}

[class*=' Account__SublinksContainer'] a:nth-child(2) {
  display: none !important;
}

.inplayer-paywall #abonnements {
  display: none !important;
}

/** hide Konto löschen */ 
.inplayer-paywall [class*='Account__TabsWrapper'] [class*='Tab']:nth-child(2) {
  display: none !important
}